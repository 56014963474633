<template>
  <div>
    <page-header></page-header>

    <section class="home-banner pos-r">
      <div class="swiper-container home-banner-item">
        <div class="swiper-wrapper">
          <div class="swiper-slide pointer f0 img-auto">
            <img src="../assets/images/banner.jpg" alt="" />
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </section>

    <section class="home-floor1">
      <div class="w1100px">
        <h2 class="txt-c c-green f36 pb46">
          <b>山丘联康 - 数字化慢病管理机构</b>
        </h2>
        <p class="lh-16 pb18">
          上海山丘联康健康管理有限公司，是一家为糖尿病、肥胖症、脂肪肝等代谢性慢病人群提供多学科健康管理服务的数字化慢病管理机构。总部位于上海大虹桥医疗板块，临近虹桥国际机场和高铁站，占地总面积近4000平米，设施齐全；在全国3个城市（上海、深圳、常州）分布有健康管理中心。
        </p>
        <section class="home-swiper pos-r">
          <div class="swiper-container home-swiper-item">
            <div class="swiper-wrapper">
              <div class="swiper-slide img-auto pointer f0">
                <img src="../assets/images/about1.jpg" alt="" />
              </div>
              <div class="swiper-slide img-auto pointer f0">
                <img src="../assets/images/about2.jpg" alt="" />
              </div>
              <div class="swiper-slide img-auto pointer f0">
                <img src="../assets/images/about3.jpg" alt="" />
              </div>
              <div class="swiper-slide img-auto pointer f0">
                <img src="../assets/images/about4.jpg" alt="" />
              </div>
              <div class="swiper-slide img-auto pointer f0">
                <img src="../assets/images/about7.jpg" alt="" />
              </div>
              <div class="swiper-slide img-auto pointer f0">
                <img src="../assets/images/about8.jpg" alt="" />
              </div>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </section>
        <h2 class="txt-c c-green f36 pt90 pb46">
          <b>京东健康 - 糖尿病中心指定服务机构</b>
        </h2>
        <p class="lh-16 pb60">
          2021年3月25日，山丘联康与京东健康在北京京东集团总部举办战略合作签约仪式，双方合作共建“京东健康糖尿病营养康复中心”，为全国慢病患者提供更权威、更专业的线上+线下慢病管理服务。山丘联康首席医学官邹大进教授（首届国之名医、上海市医学会糖尿病学会前任主任委员、）与京东健康签约入驻为“京东健康糖尿病营养康复中心”学术带头人。
        </p>
        <div class="flex all-c space-b">
          <p class="f0 img-shadow-small">
            <img src="../assets/images/about5.jpg" width="540" alt="" />
          </p>
          <p class="f0 img-shadow-small">
            <img src="../assets/images/about6.jpg" width="540" alt="" />
          </p>
        </div>
      </div>
    </section>

    <!-- <section class="home-floor2">
      <div class="w1100px">
        <h2 class="txt-c c-green f36 pb46"><b>山丘联康管理效果</b></h2>
        <p class="lh-16 pb46">
          2型糖尿病的逆转程度、所需管理周期和患者的病情、病程、年龄、管理过程中的配合程度相关。山丘联康所有管理目标，经专家团评估后，都会在合同中清晰呈现，签约承诺无效退款。
        </p>
        <p class="img-auto f0 img-shadow">
          <img src="../assets/images/effect1.png" alt="" />
        </p>
        <p class="lh-16 pb30 pt30">
          山丘联康已成功服务近千名2型糖尿病/肥胖/脂肪肝患者，效果显著，有将近一半的患者做到了完全停药。
        </p>
        <p class="img-auto f0 img-shadow">
          <img src="../assets/images/effect2.png" alt="" />
        </p>
        <template v-if="productList.length">
          <h2 class="txt-c c-green f36 pt90 pb46"><b>山丘联康逆转案例</b></h2>
          <div class="flex all-t space-b video-list">
            <div class="" v-for="(item, index) in productList" :key="index">
              <p class="f0 pos-r" @click="goVideoDetail(item)">
                <span class="play">
                  <img src="../assets/images/play.png" width="44" alt="" />
                </span>
                <img :src="item.pic" width="346" height="195" alt="" />
              </p>
              <h3 class="lh-16 pt13">{{ item.title }}</h3>
            </div>
          </div>

          <div class="pt73 txt-c">
            <a href="/case" class="btn w284px">了解更多案例</a>
          </div>
        </template>
      </div>
    </section> -->

    <section class="home-floor3">
      <div class="w1200px">
        <h2 class="txt-c c-green f36 pb68"><b>山丘联康发展历程</b></h2>
        <p class="f0 img-auto">
          <img src="../assets/images/history.jpg" alt="" />
        </p>
      </div>
      <div class="w1100px">
        <h2 class="txt-c c-green f36 pt100 pb60"><b>山丘联康合作伙伴</b></h2>
        <p class="lh-16 pb38">
          中国人保财险、太平洋保险、中国平安、永诚保险、东吴人寿、永安保险、中国大地保险、亚太财险
        </p>
        <div class="flex f-wrap customer-list">
          <a href=""><img src="../assets/images/c1.jpg" alt="" /></a>
          <a href=""><img src="../assets/images/c2.jpg" alt="" /></a>
          <a href=""><img src="../assets/images/c3.jpg" alt="" /></a>
          <a href=""><img src="../assets/images/c4.jpg" alt="" /></a>
          <a href=""><img src="../assets/images/c5.jpg" alt="" /></a>
          <a href=""><img src="../assets/images/c6.jpg" alt="" /></a>
          <a href=""><img src="../assets/images/c7.jpg" alt="" /></a>
          <a href=""><img src="../assets/images/c8.jpg" alt="" /></a>
        </div>
      </div>
    </section>

    <page-footer />
  </div>
</template>
<script>
import PageHeader from '@/components/page-header';
import PageFooter from '@/components/page-footer';
export default {
  name: 'home',
  components: { PageHeader, PageFooter },
  data() {
    return {
      productList: [],
      banner1: require('../assets/images/banner.jpg')
    };
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      var mySwiper = new Swiper('.home-swiper-item', {
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
      var bannerSwiper = new Swiper('.home-banner-item', {
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
        },
      });
    });
  },
  methods: {
    async init() {
      try {
        await this.getProductList();
      } catch (e) {
        this.$error(e.message);
      }
    },
    async getProductList() {
      let result = await this.$store.dispatch('getData', {
        url: '/api/n/advlList',
        data: { position: 2, type: 10, pageNum: 1, pageSize: 3 },
      });
      result.rows.forEach((item) => {
        const picArr = item.pic.split('|');
        item.pic = picArr[0];
        item.videoUrl = JSON.parse(picArr[1])[0].url;
      });
      this.productList = result.rows;
    },
    goVideoDetail(item) {
      this.go(`/video-detail?id=${item.id}`);
    },
  },
};
</script>
