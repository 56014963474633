import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: { keepAlive: true, deepth: 1, title: '首页' }
  },
  {
    path: '/home-m',
    redirect: '/'
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue'),
    meta: { keepAlive: true, deepth: 1, title: '关于我们' }
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case.vue'),
    meta: { keepAlive: true, deepth: 2, title: '逆转案例' }
  },
  {
    path: '/experts',
    name: 'experts',
    component: () => import('../views/experts.vue'),
    meta: { keepAlive: true, deepth: 1, title: '专家列表' }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue'),
    meta: { keepAlive: true, deepth: 1, title: '山丘新闻' }
  },
  {
    path: '/news-detail',
    name: 'news-detail',
    component: () => import('../views/news-detail.vue'),
    meta: { keepAlive: true, deepth: 2, title: '山丘新闻' }
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: () => import('../views/solutions.vue'),
    meta: { keepAlive: true, deepth: 1, title: '山丘新闻' }
  },
  {
    path: '/video-detail',
    name: 'video-detail',
    component: () => import('../views/video-detail.vue'),
    meta: { keepAlive: true, deepth: 2, title: '案例详情' }
  }
];

export default new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive && store.state.keepAliveInclude.indexOf(to.name) > -1) {
      return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 1 });
      }, 0);
    });
  }
});
