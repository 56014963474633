<template>
  <div>
    <page-header></page-header>

    <section class="case-banner img-auto f0">
      <img src="../assets/images/banner1.jpg" alt="" />
    </section>

    <section class="home-floor2 case-floor">
      <div class="w1100px">
        <h2 class="txt-c c-green f36 pt90 pb46"><b>山丘联康逆转案例</b></h2>
        <div class="flex all-t f-wrap video-list">
          <div v-for="(item, index) in productList" :key="index">
            <p class="f0 pos-r" @click="goVideoDetail(item)">
              <span class="play">
                <img src="../assets/images/play.png" width="44" alt="" />
              </span>
              <img :src="item.pic" width="346" height="195" alt="" />
            </p>
            <h3 class="lh-16 pt13">{{ item.title }}</h3>
          </div>
        </div>
      </div>
    </section>

    <page-footer></page-footer>
  </div>
</template>
<script>
import PageHeader from '@/components/page-header';
import PageFooter from '@/components/page-footer';
export default {
  name: 'case',
  components: { PageHeader, PageFooter },
  data() {
    return {
      productList: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        await this.getProductList();
      } catch (e) {
        this.$error(e.message);
      }
    },
    async getProductList() {
      let result = await this.$store.dispatch('getData', {
        url: '/api/n/advlList',
        data: { position: 2, type: 10, pageNum: 1, pageSize: 60 },
      });
      result.rows.forEach((item) => {
        const picArr = item.pic.split('|');
        item.pic = picArr[0];
        item.videoUrl = JSON.parse(picArr[1])[0].url;
      });
      this.productList = result.rows;
    },
    goVideoDetail(item) {
      this.go(`/video-detail?id=${item.id}`);
    },
  },
};
</script>
