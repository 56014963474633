<template>
  <div>
    <footer class="g-footer f16">
      <div class="w1200px flex all-c space-b">
        <a href="/about" class="contact">联系我们</a>
        <p class="opt-65">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >沪ICP备19034165号</a
          >
          <span class="pl32">Copyright © 上海山丘联康健康管理有限公司</span>
        </p>
      </div>
    </footer>

    <section class="g-side txt-c">
      <div class="wechat flex all-c">
        <div>
          <p><span class="icon icon-weixin666"></span></p>
          <h3 class="f14">逆糖咨询</h3>
        </div>

        <div class="wechat-pop" style="height: 245px">
          <p><img src="../assets/images/wechat3.jpg" width="130" alt="" /></p>
          <h4 class="pt10 f16">扫一扫咨询微信客服</h4>
        </div>
      </div>
      <div class="wechat flex all-c">
        <div>
          <p><span class="icon icon-weixin666"></span></p>
          <h3 class="f14">商户合作</h3>
        </div>

        <div class="wechat-pop" style="height: 275px">
          <p><img src="../assets/images/wechat4.png" width="130" alt="" /></p>
          <h4 class="pt10 f16">扫一扫咨询微信客服</h4>
        </div>
      </div>
      <div class="phone flex all-c">
        <div>
          <p><span class="icon icon-dianhua"></span></p>
          <h3 class="f14">服务热线</h3>
        </div>
        <div class="phone-pop">
          <p><b class="f20 c-green1">021-62216222</b></p>
          <h4 class="f16 c-333">全国咨询服务热线</h4>
        </div>
      </div>
      <div class="wechat-public flex all-c">
        <div>
          <p><span class="icon icon-weixin666"></span></p>
          <h3 class="f14">官方公众号</h3>
        </div>
        <div class="wechat-pop">
          <p>
            <img src="../assets/images/wechat-public.jpg" width="130" alt="" />
          </p>
          <h4 class="pt10 f16">扫一扫关注官方微信</h4>
        </div>
      </div>
      <div class="flex back-top all-c">
        <div>
          <p><span class="icon icon-huojian f32"></span></p>
          <h3 class="f14">返回顶部</h3>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'page-footer',
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      $('.back-top').click(function () {
        $('body,html').animate({ scrollTop: 0 }, 800);
      });

      $('.solution-tab')
        .find('a')
        .hover(function () {
          $('.solution-tab').find('a').removeClass('on');
          $(this).addClass('on');
          $('.solution-tab-content').find('p').hide();
          $($('.solution-tab-content').find('p')[$(this).index()]).show();
        });
    });
  },
  methods: {},
};
</script>
