<template>
  <div>
    <page-header></page-header>

    <section class="solution-banner">
      <div class="video-play" @click="showPlayer = true">
        <img src="../assets/images/icon-play.png" width="64" alt="" />
      </div>
      <div class="img-auto f0"><img src="../assets/images/banner-case.png" alt=""></div>
      <video-play url="https://myunonline-shanqiu.oss-cn-hangzhou.aliyuncs.com/1629726907072.mp4" v-model="showPlayer" />
    </section>

    <section class="home-floor2">
      <div class="w1200px">
        <h2 class="txt-c c-green f36 pb82"><b>逆转核心理念</b></h2>
        <p class="sub-title f18 pb18"><span>逆转核心理念 ——</span></p>
        <h3 class="title-divider pos-r mb40">
          <b class="f32 pr10">2型糖尿病的逆转</b>
        </h3>

        <div class="w1100px">
          <p class="pb30">
            2型糖尿病的逆转：经过多学科的专业管理，代谢相关指标（血糖/血脂/血压/体脂）得到大幅度好转，达到或者接近达到正常范围。在管理过程中，患者的糖脂代谢功能得到部分修复，糖脂代谢能力提升，从而减少药物使用的剂量。
          </p>
          <div class="pb82">
            <p class="img-shadow">
              <img src="../assets/images/solution1.jpg" width="1100" alt="" />
            </p>
          </div>
        </div>

        <p class="sub-title f18 pb18"><span>逆转核心理念 ——</span></p>
        <h3 class="title-divider pos-r mb40">
          <b class="f32 pr10">逆转理论基础</b>
        </h3>

        <div class="pos-r mb30">
          <div class="basic-info">
            <p class="pb30">
              早期糖尿病患者体内胰岛功能尚存50%-60%。胰岛β细胞在高血糖和各种有害因素的压力下，并不是已经“死亡”，而只是“休眠”，及时采取干预措施，从源头上阻止胰岛β细胞损伤，可以有效保护胰岛β细胞的功能，重新“激活”受损的β细胞。
            </p>
            <p>
              欧洲糖尿病研究协会（EASD）2020年会上最新发表一项衍生自DiRECT的研究显示，逆转的糖尿病人胰腺在逐步恢复正常的形态与大小，说明了2型糖尿病的逆转不仅是功能的恢复，在生理形态也在恢复，有机会走向治愈。
            </p>
          </div>

          <div class="extend-info">
            <div class="extend-info-txt">
              <h4 class="pb30"><b class="f28 c-green2">Direct 延伸试验</b></h4>
              <p class="c-green2 pb13">
                2年糖尿病缓解者胰腺形态和胰岛素分泌功能基本接近正常水平
              </p>
              <ul>
                <li>干预后缓解者（N=32）</li>
                <li>干预后为缓解者（N=13）</li>
                <li>糖尿病对照组（N=16）</li>
              </ul>
            </div>

            <div class="extend-footer flex all-c">
              <p class="pr10">
                <img src="../assets/images/solution3.png" width="60" alt="" />
              </p>
              <div class="flex-1">
                <p><b>Elizabeth Roberson博士（Diabetes UK）: </b></p>
                <p>
                  DiRECT试验颠覆了对2型糖尿病的看法——我们不再将其视为终生疾病，部分患者是有可能缓解的。
                </p>
              </div>
            </div>
          </div>

          <p class="basic-pic">
            <img src="../assets/images/solution2.png" width="500" alt="" />
          </p>
        </div>
        <div class="w1100px">
          糖尿病逆转不是只着眼于血糖指标的改变，在血糖指标达标的基础上，更重要的是整体代谢能力的提升，这样才能长期保持血糖的平稳，减少并发症的发生，延缓发展。
        </div>
      </div>
    </section>

    <section class="home-floor1">
      <div class="w1200px">
        <h2 class="txt-c c-green f36 pb82"><b>山丘联康解决方案</b></h2>
        <p class="sub-title f18 pb18"><span>解决方案 ——</span></p>
        <h3 class="title-divider pos-r mb40">
          <b class="f32 pr10">山丘联康MPD平衡法</b>
        </h3>

        <div class="w1100px">
          <p class="mb40">
            山丘联康联合创始人——艾瑞克·西曼教授和山丘联康首席医学官——邹大进教授在内的中国专家，在全面引进国外先进理念和科学方法的基础上，结合中国实践，独家首创代谢性慢病管理的“MPD平衡法”，以大数据和AI算法为工具，向糖尿病、肥胖症、脂肪肝等代谢性慢病患者，提供横跨医学/营养/运动/心理四大学科、个性化、动态平衡的健康管理解决方案，通过多学科综合管理，从根本上提升患者糖脂代谢能力。
          </p>

          <p class="bg-solution mb10">
            <img src="../assets/images/solution4.png" width="1100" alt="" />
          </p>
          <p class="bg-solution-b mb40">
            <img src="../assets/images/solution6.png" width="1100" alt="" />
          </p>
          <p class="bg-solution-c mb30">
            <img src="../assets/images/solution7.jpg" width="1100" alt="" />
          </p>
          <p>
            只依靠药物控制血糖，药物越用越多，血糖越控越差。只有提升人体自身糖脂代谢能力，才能实现停药/减药。只有掌握控糖技能，才能保持各项代谢指标的长期稳定。
          </p>
        </div>

        <p class="sub-title f18 pb18 pt90"><span>解决方案 ——</span></p>
        <h3 class="title-divider pos-r mb40">
          <b class="f32 pr10">山丘联康管理方式</b>
        </h3>

        <div class="solution-tab flex all-c txt-c f22 mb40">
          <a href="javascript:void(0);" class="flex-1 flex all-c on"
            ><span>多学科高级专家订制<br />个性化方案</span></a
          >
          <a href="javascript:void(0);" class="flex-1 flex all-c"
            ><span>核心专属控糖减脂产品</span></a
          >
          <a href="javascript:void(0);" class="flex-1 flex all-c"
            ><span>智能硬件动态监控<br />+控糖工具包</span></a
          >
          <a href="javascript:void(0);" class="flex-1 flex all-c"
            ><span>线上+线下全时管理</span></a
          >
        </div>

        <div class="solution-tab-content txt-c">
          <p style="display: block" class="img-shadow-small-b">
            <img src="../assets/images/solution8.jpg" width="1100" alt="" />
          </p>
          <p>
            <img src="../assets/images/solution9.jpg" width="1100" alt="" />
          </p>
          <p>
            <img src="../assets/images/solution10.jpg" width="1100" alt="" />
          </p>
          <p>
            <img src="../assets/images/solution11.jpg" width="1100" alt="" />
          </p>
        </div>

        <p class="sub-title f18 pb18 pt90"><span>解决方案 ——</span></p>
        <h3 class="title-divider pos-r mb40">
          <b class="f32 pr10">山丘联康管理流程</b>
        </h3>

        <div class="pb68">
          <p class="img-shadow-small-b">
            <img src="../assets/images/solution12.jpg" width="1200" alt="" />
          </p>
        </div>
      </div>
    </section>

    <page-footer></page-footer>
  </div>
</template>
<script>
import PageHeader from '@/components/page-header.vue';
import PageFooter from '@/components/page-footer.vue';
import VideoPlay from '@/components/video-play';
export default {
  name: 'solutions',
  components: { PageHeader, PageFooter, VideoPlay },
  data() {
    return {
      showPlayer: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>
