<template>
  <header class="g-header">
    <div class="w1200px flex all-c">
      <h1 class="f0 flex-1">
        <a href="/"
          ><img src="../assets/images/logo.png" alt="" width="138"
        /></a>
      </h1>
      <nav class="flex all-c">
        <a href="/" :class="{ on: pathName === 'home' }">首页</a>
        <a href="/solutions" :class="{ on: pathName === 'solutions' }"
          >逆糖/控糖方案</a
        >
        <a href="/experts" :class="{ on: pathName === 'experts' }">专家团队</a>
        <a href="/news" :class="{ on: pathName === 'news' }">健康频道</a>
        <a href="/about" :class="{ on: pathName === 'about' }">关于我们</a>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  name: 'page-header',
  data() {
    return {};
  },
  computed: {
    pathName() {
      return this.$route.name;
    },
  },
  mounted() {},
  methods: {},
};
</script>
