<template>
  <div id="app">
    <keep-alive :include="$store.state.keepAliveInclude">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {};
  },
  beforeDestroy() {
    this.$store.commit('updateKeepAliveInclude', []);
  },
  watch: {
    $route(to, from) {
      let include = this.$store.state.keepAliveInclude;
      if (to.meta.keepAlive) {
        !include.includes(to.name) && include.push(to.name);
      }
      if ((to.meta.deepth || 0) <= from.meta.deepth) {
        let index = include.indexOf(from.name);
        index !== -1 && include.splice(index, 1);
      }
      this.$store.commit('updateKeepAliveInclude', include);
    },
  },
};
</script>
