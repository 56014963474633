<template>
  <el-dialog width="60%" :visible.sync="show" @open="open" @close="close">
    <div id="player"></div>
  </el-dialog>
</template>
<script>
import Player from 'xgplayer';
export default {
  name: 'video-play',
  data() {
    return {
      show: false,
      player: undefined,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
  },
  watch: {
    value(val) {
      this.show = val;
    },
  },
  methods: {
    open() {
      this.$nextTick(() => {
        console.log(this.url);
        this.player = new Player({
          id: 'player',
          url: this.url,
          autoplay: true,
          fluid: true,
          lang: 'zh-cn',
        });
      });
    },
    close() {
      this.$emit('input', this.show);
      this.player.once('destroy', () => {
        this.player = undefined;
        console.log('播放器已销毁');
      });
      if (this.player) {
        this.player.destroy();
      }
    },
  },
};
</script>
