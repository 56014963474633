const mixin = {
  metaInfo: {
    title: '山丘联康，健康管理',
    meta: [{
      name: 'keyWords',
      content: '山丘，山丘联康，健康管理'
    },
    {
      name: 'description',
      content: '上海山丘联康健康管理有限公司，是一家为糖尿病、肥胖症、脂肪肝等代谢性慢病人群提供多学科健康管理服务的专业机构。'
    }]
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    laterGo(path, replace) {
      setTimeout(() => {
        replace ? this.$router.replace(path) : this.$router.push(path);
      }, 1500);
    },
    goBack() {
      this.$router.go(-1);
    },
    laterGoBack() {
      setTimeout(() => {
        this.goBack();
      }, 1000);
    },
    $error(message) {
      console.error(message);
    },
    $success(message) {
      console.log(message);
    },
    $toast(message) {
      console.log(message);
    }
  }
};

export default mixin;
