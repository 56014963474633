<template>
  <div>
    <page-header></page-header>

    <div class="g-body">
      <section class="w1200px g-bread f16">
        <span class="icon icon-xuanzhongshangcheng c-999"></span>
        <span> 位置：</span>
        <span class="c-green2 pointer" @click="go('/')">首页</span>
        <span> > </span>
        <span class="c-green2 pointer" @click="goBack">{{ first }}</span>
        <span> > </span>
        <span>{{ second }}</span>
      </section>

      <div class="flex all-c">
        <div id="player"></div>
      </div>

      <section class="w1200px news-content pt30">
        <h1 class="f36 txt-c">
          <b>{{ product.title }}</b>
        </h1>
        <p class="f16 c-999">
          <span>2021-07-22</span>
          <span class="pl32">阅读：{{ product.count }}</span>
        </p>
        <div class="html-content" v-html="product.content"></div>
      </section>
    </div>

    <page-footer></page-footer>
  </div>
</template>
<script>
import Player from 'xgplayer';
import PageHeader from '@/components/page-header.vue';
import PageFooter from '@/components/page-footer.vue';
export default {
  name: 'video-detail',
  components: { PageHeader, PageFooter },
  data() {
    return {
      product: {},
      player: undefined,
    };
  },
  computed: {
    first() {
      return this.$route.query.f || '逆转案例';
    },
    second() {
      return this.$route.query.s || '案例详情';
    },
  },
  destroyed() {
    this.player.once('destroy', () => {
      this.player = undefined;
      console.log('播放器已销毁');
    });
    if (this.player) {
      this.player.destroy();
    }
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      try {
        let result = await this.$store.dispatch('getData', {
          url: '/api/n/advInfo',
          data: { id: this.$route.query.id },
        });
        const picArr = result.data.pic.split('|');
        result.data.pic = picArr[0];
        result.data.videoUrl = JSON.parse(picArr[1])[0].url;
        this.product = result.data;
        this.$nextTick(() => {
          this.player = new Player({
            id: 'player',
            width: 845,
            height: 475,
            poster: this.product.pic,
            url: this.product.videoUrl,
            lang: 'zh-cn',
          });
        });
      } catch (e) {
        this.$error(e.message);
      }
    },
  },
};
</script>