import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mixins from './mixins/global';
import { Pagination, Dialog } from 'element-ui';
import MetaInfo from 'vue-meta-info'
import 'element-ui/lib/theme-chalk/index.css';
import 'assets/css/style.css';

Vue.config.productionTip = false;
Vue.mixin(mixins);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(MetaInfo);

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');
