import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import api from '../api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    keepAliveInclude: []
  },
  mutations: {
    updateKeepAliveInclude: (state, data) => {
      state.keepAliveInclude = data;
    },
    clearKeepAliveInclude: (state) => {
      state.keepAliveInclude = [];
    }
  },
  actions: {
    async postData({ commit, rootState }, { url, data }) {
      try {
        const res = await api.post(url, data);
        if (res) {
          if (res.code === 0 || res.code === 200) {
            return res;
          } else {
            throw new Error(res.msg);
          }
        }
      } catch (error) {
        throw error;
      }
    },
    async getData({ commit, rootState }, { url, data }) {
      try {
        const res = await api.get(url, { params: data });
        if (res) {
          if (res.code === 0 || res.code === 200) {
            return res;
          } else {
            throw new Error(res.msg);
          }
        }
      } catch (error) {
        throw error;
      }
    }
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
});
